<template>
  <router-link
    :to="{ name: names.laboratory, params: { id: card.id } }"
    class="recent-card"
  >
    <div class="recent-card__wrap">
      <picture class="recent-card__picture">
        <source
          v-for="(srcset, media) in mediaSrcsets"
          :key="media"
          :media="media"
          :srcset="srcset"
        />
        <img
          src="/img/empty/card.png"
          :alt="card.name"
          class="recent-card__img"
        />
      </picture>
      <div class="recent-card__play">
        <IconPlay />
      </div>
      <div class="recent-card__title">
        {{ card.name }}
      </div>
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue';
import { names } from '@/constants/routes';
import { imageCandidatesToSrcset } from '@/utils';
import { LabData } from '@/types/LabData';
import IconPlay from '@/components/icons/Play.vue';

const props = defineProps<{
  card: LabData;
}>();
const appImg = toRef(() => props.card.app_img);

const mediaSrcsets = computed(() => ({
  '(max-width: 575px)': imageCandidatesToSrcset({
    '2x': appImg.value['800x560'],
  }),
  '(min-width: 576px)': imageCandidatesToSrcset({
    '1x': appImg.value['400x280'],
  }),
}));
</script>

<style scoped lang="scss">
@import "@/assets/style/include";

$playTranslate: translate(-50%, calc(-70% - 12px));

.recent-card {
  display: block;
  position: relative;
  width: 100%;
  transition: border-color $transition-speed;
  padding-right: $carouselGap;

  &:hover {

    .recent-card__wrap {
      border: 2px solid $colorBlue1;
    }

    .recent-card__img {
      filter: blur(7px);
      border-radius: 0 0 20px 20px;
    }
    .recent-card__title {
      opacity: 1;
    }
    .recent-card__play {
      transform: $playTranslate scale(1.1);
      opacity: 1;
    }
  }
}

.recent-card__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: $playTranslate;
    transition: all $transition-speed;
    opacity: 0;
    z-index: 2;
    width: 30%;
    height: 35%;
    color: white;

    svg {
      width: 100%;
      height: 100%;
    }
}

.recent-card__wrap {
  position: relative;
  overflow: hidden;
  border-radius: 14px;
  border: 2px solid transparent;
}

.recent-card__picture {
  height: 100%;
}

.recent-card__title {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: white;
  box-shadow: 0 0 1px 1px white;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  opacity: 0;
  transition: opacity $transition-speed;
}

.recent-card__img {
  width: 100%;
  object-fit: cover;
  transition: all $transition-speed;
}

@include media-breakpoint-down("mobile") {
  .recent-card {
    padding-left: 0;
    padding-right: 4px;
  }
  .recent-card__img {
    max-height: 180px;
  }
}

</style>
